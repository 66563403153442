<template>

  <div>

    <XModal ref="XModal" :show-overlay="true">

      <template #header>{{ itemData.process_type_name ?? 'Просмотр карточки процесса' }}</template>

      <template #default>

        <div style="min-width: 900px; max-width: 80vw">

          <TabView>
            <TabPanel header="Общее">

              <table class="p-datatable-table" style="width: 100%;">
                <tbody>

                <tr>
                  <td>Участники</td>
                  <td>
                    <ul style="padding-left: inherit;">
                      <li v-for="(role, $index) in itemData.roles" :key="$index">
                                                <span v-if="orgInfo()?.guid === role.agentGuid"
                                                      style="color: var(--teal-500)">{{
                                                    role.agentRoleName
                                                  }} - {{ role.agentTitle }}</span>
                        <span v-else>{{ role.agentRoleName }} - {{ role.agentTitle }}</span>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr v-if="things_loading">
                  <td class="text-center">
                    <ProgressSpinner strokeWidth="8" style="width: 20px; height: 20px"/>
                  </td>
                  <td class="text-center">
                    <ProgressSpinner strokeWidth="8" style="width: 20px; height: 20px"/>
                  </td>
                </tr>

                <tr v-for="(data, name) in data_for_coordination_sorted" v-else :key="name"
                >
                  <td>{{ name }}</td>
                  <td>
                    <table class="xtable">
                      <tr v-for="(thing_value,thing_name) in data" :key="thing_name">
                        <td>{{ parseThingName(thing_name) }}</td>
                        <td class="pl-4">{{ thing_value[0]?.value }}</td>
                      </tr>
                    </table>
                    <!--                    <ul>
                                          <li v-for="(thing_value,thing_name) in data" :key="thing_name" class="flex">
                                            <div>{{thing_name}}</div>
                                            <div class="mr-3">:</div>
                                            <div>{{thing_value[0]?.value}}</div>
                                          </li>
                                        </ul>-->

                  </td>
                </tr>

                <tr>
                  <td>Идентификатор процесса</td>
                  <td>{{ itemData.guid }}</td>
                </tr>

                <tr>
                  <td>Вид процесса</td>
                  <td>{{ itemData.process_type_name }}</td>
                </tr>

                <tr>
                  <td>Название процесса</td>
                  <td>{{ itemData.process_view }}</td>
                </tr>

                <tr>
                  <td>Инициатор процесса</td>
                  <td>{{ itemData.creator_title }}</td>
                </tr>

                </tbody>
              </table>

            </TabPanel>
            <TabPanel header="События">

              <Timeline :value="processEvents" StyleClass="xTimeLine">
                <template #opposite="slotProps">
                  <small
                    class="p-text-secondary">{{ unixTimeStampToDateTime(slotProps.item.time) }}</small>
                </template>
                <template #content="slotProps">
                  <div style="color: var(--yellow-500);">{{ slotProps.item.event_type_name }}</div>
                  <div style="color: var(--teal-500);">{{ slotProps.item.status }}</div>
                  <div style="color: var(--bluegray-100);">Отправитель:
                    {{ slotProps.item.sender_title }}
                  </div>
                  <Accordion>
                    <AccordionTab header="Получатели">
                      <div style="color: var(--gray-500);">
                        <ul style="padding: 0; margin: 0 0 0 20px;">
                          <li v-for="(receiver, $index2) in slotProps.item.receivers"
                              :key="$index2" class="mb-2"> {{ receiver.name }}
                          </li>
                        </ul>
                      </div>
                    </AccordionTab>
                  </Accordion>


                </template>

              </Timeline>


            </TabPanel>
          </TabView>


        </div>


      </template>

      <template #footerLeftOfHide>
        <Button class="p-button-success p-button-outlined" label="Выбрать" @click="selectClicked"/>
      </template>


    </XModal>


  </div>

</template>

<script>

import {DataFilters, XModal} from "@xnpmpackages/xcomponents";
import ProgressSpinner from "primevue/progressspinner";
import Xapi from "@xnpmpackages/xcomponents/dist/utils/xapi";


export default {
  name: "ProcessSelectorCard",
  emits: ['selectClicked'],
  mixins: [DataFilters],
  inject: ['orgInfo'],
  data() {
    return {
      itemData: null,
      processEvents: null,
      data_for_coordination: null,
      things_loading: true,
    }
  },
  methods: {

    parseThingName(thing) {

      thing = thing.replaceAll('/', ' ').replace('@', ' ')
      thing = thing.replaceAll(/([А-Я]+)/gm, ' $1')

      return thing;

    },

    async getEvents() {

      let response = await this.$xapi.post(
        '/constructor',
        {
          action: 'getEvents',
          processGuid: this.itemData.guid
        },
      );

      this.processEvents = response.data.events;

    },

    async getProcessInfo($processGuid) {

      try {

        this.$xapi.showBlockModal('Запрос информации о процессе...');

        let response = await this.$xapi.post(
          '/constructor',
          {
            action: 'getProcessInfo',
            guid: $processGuid,

          },
        );

        return response.data.processInfo;

      } finally {
        this.$xapi.hideBlockModal();
      }

    },

    async selectClicked() {

      let $parentProcessInfo = await this.getProcessInfo(this.itemData.parentProcessGuid);
      await this.$refs.XModal.hide();
      this.$emit('selectClicked', {
        process: this.itemData,
        events: this.processEvents,
        parentProcess: $parentProcessInfo
      });
    },

    async getFullProcessInfo() {

      this.things_loading = true

      try {

        let response = await Xapi.post(
          '/service',
          {
            action: 'getProcessInfo',
            process_guid: this.itemData.guid
          },
        );

        this.data_for_coordination = response.data?.data?.info?.data_for_coordination;

      } finally {

        this.things_loading = false;

      }

    },

    show($itemData) {
      this.itemData = $itemData;
      this.data_for_coordination = null;
      this.getEvents();
      this.getFullProcessInfo();
      this.$refs.XModal.show();
    },


    sortObject(o) {
      var sorted = {},
        key, a = [];

      for (key in o) {
        a.push(key);
      }

      a.sort();

      for (key = 0; key < a.length; key++) {
        sorted[a[key]] = o[a[key]];
      }
      return sorted;
    }

  },
  computed: {

    data_for_coordination_sorted() {
      return this.data_for_coordination ? this.sortObject(this.data_for_coordination) : null
    }

  },
  components: {XModal, ProgressSpinner,}
}
</script>

<style scoped>
.p-datatable-table {
  border-collapse: collapse;
}

.p-datatable-table tbody > tr > td, .p-datatable-table thead > tr > td {
  padding: 5px 20px;
}

.p-datatable-table > tbody > tr:nth-child(even) {
  /*background-color: #0a3622;*/
  background-color: var(--surface-ground);
}

:deep( [styleclass="xTimeLine"] .p-timeline-event ) {

  margin-bottom: 20px !important;

}

:deep([styleclass="xTimeLine"]) .p-accordion-header-link {
  border: 0;
  padding: 5px 0 5px 0;
  margin-top: 5px;
}

:deep([styleclass="xTimeLine"]) .p-accordion-content {
  border: 0;
  padding: 5px 0 0 18px;
}

:deep([styleclass="xTimeLine"]) .p-accordion-header-link:focus {
  box-shadow: none !important;
}


</style>
<template>

    <XModal ref="XModal">

        <template #header>
            Выбор справочного значения
        </template>

      <TablePage :main-url="'/constructor/getOneRef/?ref-id='+refId" @tableItemsClick="tableItemsClick">

            <template #tableColumns="TableProps">

                <Column field="id" filter-field="id">
                    <template #header>
                        <SearchInput v-model="TableProps.filters['id']" label="Идентификатор"/>
                    </template>
                </Column>

                <Column field="title" filter-field="title">
                    <template #header>
                        <SearchInput v-model="TableProps.filters['title']" label="Наименование"/>
                    </template>
                </Column>

            </template>


        </TablePage>

    </XModal>

</template>

<script>

import {SearchInput, TablePage, XModal} from "@xnpmpackages/xcomponents";

export default {
    name: "ReferenceSelector",
    data() {
        return {
            refId: null,

            promiseResolver: null,
        }
    },
    methods: {

        async select(refId) {

            this.refId = refId;

            this.$refs.XModal.show();

            return new Promise(resolve => {
                this.promiseResolver = resolve;
            });


        },

        async tableItemsClick($event) {
            await this.$refs.XModal.hide();
            this.promiseResolver($event.data);
        }

    },
    components: {
        XModal, TablePage, SearchInput
    }
}
</script>

<style scoped>

</style>